<template>
  <v-card height="100%" tile class="pa-10">
    <v-tabs align-with-title>
      <v-tabs-slider color="primary" />
      <!-- :style="tab !== '/invitados/table?type=historial' ? '' : 'color:#479b25'" -->
      <v-tab
        exact
        :to="{
          name: 'invitados',
          query: { type: 'pendientes' },
        }"
      >
        Solicitudes Pendientes
      </v-tab>
      <v-tab
        exact
        :to="{
          name: 'invitados',
          query: { type: 'historial' },
        }"
      >
        Invitados Previos
      </v-tab>
    </v-tabs>
    <GuestTable></GuestTable>
  </v-card>
</template>


<script>
import GuestTable from "@/views/guest/GuestTable.vue";

export default {
  name: "guests",
  components: { GuestTable },
};
</script>

<style scoped>
</style>