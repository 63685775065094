<template>
  <v-row align="center" class="list px-3 mx-auto">
    <v-col cols="12" sm="12">
      <v-data-table
        :mobile-breakpoint="0"
        class="iconTable"
        :headers="headers"
        :items="guests.rows"
        disable-pagination
        :hide-default-footer="true"
        :loading="fetchingData"
        :loading-text="'Cargando... Espere por favor'"
        :no-data-text="'datos no disponibles'"
        item-key="_id"
        hide-default-header
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <template v-for="header in headers">
                <th :key="header.value" :class="header.align">
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: #000">
                        {{ header.text }}
                        <v-icon
                          v-if="header.text !== 'Detalle'"
                          color="primary"
                        >
                          mdi-arrow-down-drop-circle
                        </v-icon>
                        <v-icon v-else />
                      </h2>
                    </v-col>
                  </v-row>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #[`item.id`]="{ item }">
          <td
            :style="
              guests.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.id }}
          </td>
        </template>
        <template #[`item.user.names`]="{ item }">
          <td
            :style="
              guests.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.user.names }}
          </td>
        </template>
        <template #[`item.user.last_name`]="{ item }">
          <td
            :style="
              guests.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.user.last_name }}
          </td>
        </template>
        <template #[`item.user.document_number`]="{ item }">
          <td
            :style="
              guests.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.user.document_number }}
          </td>
        </template>
        <template #[`item.membership.user.fullname`]="{ item }">
          <td
            :style="
              guests.rows.indexOf(item) % 2 == 0
                ? 'background:#eeee!important'
                : ''
            "
          >
            {{ item.membership.user.fullname }}
          </td>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="4" sm="3">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="9">
          <v-pagination
            v-model="page"
            :length="guests.totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "table-guest",
  watch: {
    type: function (value) {
      this.getFetch();
    },
    text: function (value) {
      this.getFetch();
    },
  },
  data: () => ({
    headers: [
      { text: "N.Reserva", value: "id", width: 150 },
      { text: "Nombre", value: "user.names", width: 200 },
      { text: "Apellido", value: "user.last_name" },
      { text: "Documento", value: "user.document_number" },
      { text: "Socio", value: "membership.user.fullname" },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    // guests: [],
    //   loading: false
  }),
  computed: {
    type() {
      return this.$route.query.type;
    },
    text() {
      return this.$route.query.text;
    },
    ...mapState({
      guests: (state) => state.guest.guests,
      fetchingData: (state) => state.fetchingData,
    }),
  },
  methods: {
    ...mapActions(["fetchGuests"]),
    getRequestParams(page, pageSize) {
      let params = {};

      params["type"] = this.type;
      if (this.text) {
        params["text"] = this.text;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize, this.type);
      this.fetchGuests({ params: params });
    },
  },
  created() {
    this.getFetch();
  },
};
</script>


<style scoped>
.iconTable >>> .mdi-arrow-up {
  color: #479b25 !important;
}
.iconTable >>> .v-data-table-header__icon {
  opacity: 1;
}
strong {
  color: #479b25;
  font-size: 18px;
}
</style>