<template>
  <v-card-text class="pa-1">
    <v-container class="pa-2" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <!-- <v-card-title class="pa-2">
                <v-row dense>
                    <v-spacer />
                    <v-btn dense color="#479b25" dark class="ma-3" :to="'/socio-add'">
                    Crear nuevo socio
                    </v-btn>
                </v-row>
                </v-card-title> -->
            <!-- <v-divider /> -->
            <v-card-text class="pa-0">
              <table-guest></table-guest>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>


<script>
import TableGuest from "@/components/tables/TableGuest.vue";

export default {
  name: "GuestTable",
  components: { TableGuest },
};
</script>


<style scoped>
</style>